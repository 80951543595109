<template>
  <BaseModal
    :activeModal="show"
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="save"
    :close="false"
    :bgClose="false"
    size="md"
  >
    <template #header>
      Dodawanie nowego pracownika
    </template>
    <template #default>
      <div class="flex flex-wrap">
        <div class="flex justify-end mb-1 w-full px-1">
          <label class="inline-block">
            <input v-model="form.person.fake_person" true-value="1" false-value="0" class="w-auto" type="checkbox"> wirtualny pracownik
          </label>
        </div>
        <div class="mb-4 w-1/2 px-1">
          <label>email</label>
          <Autocomplete
            resource="duplicated-people-by-email"
            :inputClass="errors['person.email'] !== undefined ? 'border-red-300' : ''"
            :disabledInput="form.person_id !== ''"
            v-model="form.person.email"
            @selected="setPerson"
          />
          <p v-if="errors['person.email'] !== undefined" class="text-red-500 text-sm">{{ errors['person.email'] }}</p>
        </div>
        <div class="flex items-center w-1/2">
          <a v-if="form.person_id !== ''" class="text-sm" href="#" @click.prevent="clearForm">wyczyść pobraną kartotekę</a>
          <p v-else class="text-gray-500 text-xs"><em>zacznij pisać, aby przeszukać bazę</em></p>
        </div>
        <div class="mb-4 w-1/3 px-1">
          <label>imię</label>
          <Autocomplete
            resource="forenames"
            :inputClass="errors['person.first_name'] !== undefined ? 'border-red-300' : ''"
            :disabledInput="fakePerson === '1' || form.person_id !== ''"
            v-model="form.person.first_name"
          />
          <p v-if="errors['person.first_name'] !== undefined" class="text-red-500 text-sm">{{ errors['person.first_name'] }}</p>
        </div>
        <div class="mb-4 w-2/3 px-1">
          <label v-if="fakePerson === '1'">nazwa</label>
          <label v-else>nazwisko</label>
          <input :class="{ 'border-red-300': errors['person.surname'] !== undefined }" v-model="form.person.surname" type="text" :disabled="form.person_id !== ''">
          <p v-if="errors['person.surname'] !== undefined" class="text-red-500 text-sm">{{ errors['person.surname'] }}</p>
        </div>
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>Role w aplikacji <span class="text-gray-500 font-thin text-xs">wybierz jedną lub kilka</span></label>
          <select @change="addRole" :class="{ 'border-red-300': errors.roles !== undefined }" v-model="selectedRole">
            <option value="" disabled hidden>Wybierz</option>
            <option v-for="role in notSelectedRoles" :key="role.id" :value="role.id">{{ role.label }}</option>
          </select>
          <p v-if="errors.roles !== undefined" class="text-red-500 text-sm">{{ errors.roles }}</p>
        </div>
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>Aktualnie wybrane:</label>
          <div class="bg-white w-full border border-gray-400 rounded-sm px-3 py-1">
            <span v-if="selectedRoles.length === 0" class="text-gray-500 leading-1">nie wybrano</span>
            <span v-for="role in selectedRoles" :key="role.id" class="text-sm whitespace-no-wrap inline-block bg-orange-400 px-2 mr-1 rounded">
              {{ role.label }}
              <button @click="removeRole(role.id)" class="ml-1" title="usuń"><font-awesome-icon icon="times"/></button>
            </span>
          </div>
        </div>
        <div class="w-full px-1 mb-4">
          <label>
            <input class="w-auto" v-model="form.is_manager" true-value="1" false-value="0" type="checkbox">
            czy zarządza innymi pracownikami? <span class="text-gray-500 font-thin text-xs tracking-wide">zaznacz, jeśli TAK</span>
          </label>
        </div>
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>Manager</label>
          <select v-model="form.manager_id">
            <option value=""></option>
            <option v-for="manager in managers" :key="manager.id" :value="manager.id">{{ `${manager.first_name} ${manager.surname}` }}</option>
          </select>
        </div>
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>Forma zatrudnienia</label>
          <select v-model="form.employment_form_id" :class="{ 'border-red-300': errors.employment_form_id !== undefined }">
            <option value="" disabled hidden>Wybierz</option>
            <option v-for="empForm in employmentForms" :key="empForm.id" :value="empForm.id">{{ empForm.name }}</option>
          </select>
          <p v-if="errors.employment_form_id !== undefined" class="text-red-500 text-sm">{{ errors.employment_form_id }}</p>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import axios from 'axios';
import EmployeeService from '../services/EmployeeService';
import { errorHandler } from '../mixins/errorHandler';
import Autocomplete from './Autocomplete.vue';

export default {
  name: 'EmployeeAdd',
  mixins: [errorHandler],
  components: { Autocomplete },
  data() {
    return {
      form: {
        virtual: '0',
        roles: [],
        is_manager: '0',
        manager_id: '',
        employment_form_id: '',
        person_id: '',
        person: {
          fake_person: '0',
          first_name: '',
          surname: '',
        },
      },
      employmentForms: [],
      selectedRole: '',
      errors: {},
      show: false,
    };
  },
  computed: {
    employeeRoles() {
      return this.$store.getters['roles/employeeRoles'];
    },
    managers() {
      return this.$store.getters['managers/managers'];
    },
    selectedRoles() {
      return this.employeeRoles.filter(obj => this.form.roles.includes(obj.id));
    },
    notSelectedRoles() {
      return this.employeeRoles.filter(obj => !this.form.roles.includes(obj.id));
    },
    fakePerson() {
      return this.form.person.fake_person;
    },
  },
  mounted() {
    if (this.employeeRoles.length === 0) {
      this.$store.dispatch('roles/getEmployeeRoles');
    }
    if (this.managers.length === 0) {
      this.$store.dispatch('managers/getManagers');
    }
    this.getEmploymentForms();
    this.show = true;
  },
  methods: {
    save() {
      EmployeeService.addEmployee(this.form)
        .then(response => {
          const newEmployee = response.data.result;
          this.$store.dispatch('notifications/add', {
            type: 'success',
            message: 'Pracownik został dodany...',
          });
          this.$emit('changed', newEmployee);
          this.show = false;
          this.$router.push({ name: 'employee', params: { id: newEmployee.id }, query: this.$route.query });
        })
        .catch(error => {
          this.errors = this.resolveError(error);
        });
    },
    getEmploymentForms() {
      axios.get(`${process.env.VUE_APP_API_URL}/employment-forms`)
        .then(response => {
          this.employmentForms = response.data;
        })
        // eslint-disable-next-line no-console
        .catch(error => console.error(error));
    },
    addRole() {
      if (this.selectedRole !== '') {
        this.form.roles.push(this.selectedRole);
        this.selectedRole = '';
      }
    },
    removeRole(id) {
      this.form.roles = this.form.roles.filter(el => el !== id);
    },
    setPerson(payload) {
      this.form.person = {
        fake_person: 0,
        first_name: payload.first_name,
        surname: payload.surname,
        email: payload.email ? payload.email : payload.name,
      };
      this.form.person_id = payload.id;
    },
    clearForm() {
      this.form.person_id = '';
    },
  },
  watch: {
    fakePerson(val) {
      this.form.virtual = val;
      if (val === '1') {
        this.form.person.first_name = '';
      }
    },
  },
};
</script>
