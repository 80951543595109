<template>
  <nav class="flex justify-between lg:justify-start is-small" role="navigation" aria-label="pagination">
    <ul v-if="total > 1" class="flex mt-2">
      <li><a @click="current > 1 ? gotoPage(current - 1) : ''" class="pagination-link" aria-label="Poprzednia strona" :disabled="current==1"><span class="block">&lt;</span></a></li>
      <li v-if="current > 1"><a @click="gotoPage(1)" class="pagination-link" aria-label="Do początku">1</a></li>
      <li v-if="current > 4 && total > 7"><span class="pagination-ellipsis">&hellip;</span></li>
      <li v-if="current == 4 || (current >= 4 && total <= 7)"><a @click="gotoPage(2)" class="pagination-link" aria-label="Strona 2">2</a></li>
      <li v-if="current >= 5 && total <=7"><a @click="gotoPage(3)" class="pagination-link" aria-label="Strona 3">3</a></li>
      <li v-if="current > total -3 && total > 7"><a @click="gotoPage(total-4)" class="pagination-link" :aria-label="`Strona ${total-4}`">{{ total-4 }}</a></li>
      <li v-if="current > 5 && total <= 7"><a @click="gotoPage(4)" class="pagination-link" aria-label="Strona 4">4</a></li>
      <li v-if="current > total -2 && total > 7"><a @click="gotoPage(total-3)" class="pagination-link" :aria-label="`Strona ${total-3}`">{{ total-3 }}</a></li>
      <li v-if="current == 7 && total <= 7"><a @click="gotoPage(5)" class="pagination-link" aria-label="Strona 5">5</a></li>
      <li v-if="current == total && total > 7"><a @click="gotoPage(total-2)" class="pagination-link" :aria-label="`Strona ${total-2}`">{{ total-2 }}</a></li>

      <li v-if="current > 2"><a @click="gotoPage(current-1)" class="pagination-link" :aria-label="`Strona ${current-1}`">{{ current-1 }}</a></li>
      <li>
        <a v-if="!write" @click="write = true" class="pagination-link is-current" :aria-label="`Strona ${current}`" aria-current="page">{{ current }}</a>
        <input v-if="write" type="number"
          class="appearance-none bg-transparent border-none w-full h-full bg-gray-200 text-gray-800 font-bold mr-1 py-1 px-2 leading-tight focus:outline-none"
          :value="current"
          @keydown.enter = "gotoPage($event.target.value)"
          @keydown.esc = "write = false"
          min="1" :max="total"
        >
      </li>
      <li v-if="current < total - 1"><a @click="gotoPage(current+1)" class="pagination-link" :aria-label="`Strona ${current+1}`">{{ current+1 }}</a></li>

      <li v-if="current == 1 && total > 3"><a @click="gotoPage(3)" class="pagination-link" aria-label="Strona 3">3</a></li>
      <li v-if="current < 3 && total > 4"><a @click="gotoPage(4)" class="pagination-link" aria-label="Strona 4">4</a></li>
      <li v-if="current < 4 && total > 5"><a @click="gotoPage(5)" class="pagination-link" aria-label="Strona 5">5</a></li>
      <li v-if="current < 5 && total == 7"><a @click="gotoPage(6)" class="pagination-link" aria-label="Strona 6">6</a></li>
      <li v-if="current < total - 3 && total > 7"><span class="pagination-ellipsis">&hellip;</span></li>
      <li v-if="current == total - 3 && total > 7"><a @click="gotoPage(total-1)" class="pagination-link" :aria-label="`Strona ${total-1}`">{{ total-1 }}</a></li>
      <li v-if="current < total"><a @click="gotoPage(total)" class="pagination-link" aria-label="Do końca">{{ total }}</a></li>
      <li><a @click="current < total ? gotoPage(current + 1) : ''" class="pagination-link" aria-label="Następna strona" :disabled="current==total">&gt;</a></li>
    </ul>
    <ul v-else class="flex mt-2">
      <li><a class="pagination-link  is-current" aria-label="Jedna strona" :disabled="true"><span class="block">1</span></a></li>
    </ul>

    <!-- <div class="inline-block relative w-20 mt-2 ml-4"> -->
    <div class="relative mt-2 ml-4 flex items-stretch border border-gray-500 hover:border-gray-700 shadow rounded-sm">
      <button
        class="flex-shrink-0 bg-gray-500 border-gray-500 text-sm border-4 text-white py-1 px-2 cursor-default"
        type="button"
        title="Ilość wierszy na stronie"
      >
        <font-awesome-icon class="text-xl" icon="list-ol" />
      </button>
      <select
            v-model="perPage"
            class="block appearance-none w-full font-semibold text-gray-500 bg-transparent px-3 py-2 pr-8 leading-tight focus:outline-none focus:shadow-outline"
            title="wierszy na stronie"
          >
        <option
          class="appearance-none bg-gray-100 text-gray-500"
          v-for="(option, index) in perPageOptions"
          :key="index"
          :value="option"
        >
          {{ option }}
        </option>
      </select>
      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-500">
        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    current: {
      type: Number,
      required: true,
      default: 1,
    },
    total: {
      type: Number,
      required: true,
      default: 1,
    },
    value: {
      type: Number,
      required: true,
    },
    linkGen: {
      required: true,
    },
    perPageOptions: {
      type: Array,
      default: () => [5, 10, 20, 50, 100],
    },
  },
  data() {
    return {
      write: false,
    };
  },
  computed: {
    perPage: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    gotoPage(pageNumber) {
      this.$router.push(this.linkGen(pageNumber));
      this.$emit('page', pageNumber);
      this.write = false;
    },
  },
};
</script>

<style scoped>
  ul {
    @apply shadow;
  }
  li {
    @apply border;
    @apply border-r-0;
    @apply rounded-sm;
    @apply border-gray-400
  }
  li:last-child {
    @apply border-r;
  }
  li:hover {
    @apply bg-gray-400;
  }
  .pagination-link {
    @apply table-cell;
    @apply w-10;
    @apply h-10;
    @apply text-center;
    @apply align-middle;
    @apply cursor-pointer;
    @apply no-underline;
    @apply text-gray-500;
    @apply font-semibold;
  }
  .is-current {
    @apply bg-gray-500;
    @apply text-white;
    @apply font-bold;
  }
  .pagination-ellipsis {
    @apply table-cell;
    @apply w-8;
    @apply h-10;
    @apply text-center;
    @apply align-middle;
    @apply bg-white;
    @apply text-gray-500;
    @apply font-semibold;
  }
  .pagination-ellipsis:hover {
    @apply bg-white;
  }
</style>
