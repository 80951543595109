<template>
  <div>
    <div class="flex flex-wrap">
      <div class="mr-8">

        <!-- Button with addons -->
        <div class="flex flex-none items-center border border-gray-500 shadow rounded-sm">
          <input
            id="filterInput"
            class="appearance-none bg-transparent border-none w-full mr-1 py-1 px-2 leading-tight focus:outline-none"
            v-model="search"
            type="text"
            placeholder="Wpisz aby wyszukać..."
            @keyup.enter="filterGo"
            @keyup.esc="resetFilters"
          >
          <button
            class="flex-shrink-0 border-transparent border-4 text-gray-600 hover:text-gray-900 text-sm py-1 px-2 rounded"
            type="button"
            :disabled="search === ''" @click="resetFilters"
            title="wyczyść"
          ><font-awesome-icon icon="times"/></button>
          <button
            class="flex-shrink-0 bg-blue-500 hover:bg-blue-600 border-blue-500 hover:border-blue-600 text-sm border-4 text-white py-1 px-2"
            type="button"
            :disabled="search === ''"
            @click="filterGo"
          >
            Szukaj
          </button>
        </div>

      </div>
      <div class="flex items-center">
        <div v-for="(searchField, index) in searchFields" :key="index" class="flex items-center mr-4">
          <label class="font-semibold">
            <input class="mr-1 w-full sm:w-auto leading-tight" v-model="searchField.active" type="checkbox">
            <span>
              {{ searchField.label }}
            </span>
          </label>
        </div>
        <button
          @click="setSearchAll"
          class="text-gray-600 bg-transparent border border-gray-600 text-sm font-semibold rounded-sm ml-4 py-1 px-2 focus:shadow-outline"
          :class="{'hover:bg-gray-500 hover:text-gray-100 shadow': !searchAll}"
          :disabled="searchAll"
        >
          {{ searchAll ? 'wszystkie' : 'wybrane' }}
        </button>
      </div>
    </div>
    <div class="flex items-center">
      <div class="inline-block relative w-64 mt-4 mr-4">
        <div class="flex items-stretch box-border border border-gray-500 hover:border-gray-700 rounded-sm shadow">
          <select
            @change="statusChange"
            v-model="status"
            class="block appearance-none w-full bg-transparent px-4 py-2 pr-8 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option
              class="appearance-none bg-gray-100"
              v-for="(option, index) in employeeStatusOptions"
              :key="index"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
          </div>
        </div>
      </div>
      <div class="inline-block relative w-64 mt-4 mr-4">
        <div class="flex items-stretch box-border border border-gray-500 hover:border-gray-700 rounded-sm shadow">
          <select
            @change="roleChange"
            v-model="role"
            class="block appearance-none w-full bg-transparent px-4 py-2 pr-8 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option class="appearance-none bg-gray-100" :value="null">Rola (wszystkie)</option>
            <option
              class="appearance-none bg-gray-100"
              v-for="role in employeeRoles"
              :key="role.id"
              :value="role.id"
            >
              {{ role.label }}
            </option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
          </div>
        </div>
      </div>
      <div class="inline-block relative w-64 mt-4">
        <div class="flex items-stretch box-border border border-gray-500 hover:border-gray-700 rounded-sm shadow">
          <button
            class="flex-shrink-0 bg-gray-500 border-gray-500 text-sm border-4 text-white py-1 px-2 cursor-default"
            type="button"
            title="Pole i kierunek sortowania"
          >
            <svg class="fill-current text-white h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6 3l-6 8h4v10h4v-10h4l-6-8zm16 14h-8v-2h8v2zm2 2h-10v2h10v-2zm-4-8h-6v2h6v-2zm-2-4h-4v2h4v-2zm-2-4h-2v2h2v-2z"/></svg>
          </button>
          <select
            @change="selectSort"
            v-model="sortSelected"
            class="block appearance-none w-full bg-transparent px-4 py-2 pr-8 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option
              class="appearance-none bg-gray-100"
              v-for="(option, index) in sortOptions"
              :key="index"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
          </div>
        </div>
      </div>
      <div class="w-full mt-4">
        <button @click="showEmployeeAdd = true" class="py-2 px-3 ml-2 float-right bg-blue-400 hover:bg-blue-600 text-blue-100 hover:text-white rounded-sm" title="dodaj pracownika">
          <font-awesome-icon icon="plus" /><font-awesome-icon icon="user-tie" />
        </button>
      </div>
    </div>
    <hr class="mt-2 border-gray-300">

    <Pagination
      :current="pagData.meta.current_page"
      :total="pagData.meta.last_page"
      v-model="pagData.perPage"
      @perPageChanged="perPageChanged"
      :linkGen="linkGen"
    />

    <div class="list-group">
    <!-- <div class="block"> -->
      <div class="hidden md:flex w-full py-1 px-5 border-b border-gray-400 font-semibold">
        <div
          v-for="(col, index) in columns"
          :key="index"
          class="flex"
          :class="[{'cursor-pointer': col.sortable, 'text-right': index === 4}, col.classes]"
          @click="col.sortable ? sortSelected = `${col.name},${activeSortObj.field === col.name && activeSortObj.dir === 'asc' ? 'desc' : 'asc'}` : null"
        >
          <span class="font-bold">{{ col.label }}</span>
          <div v-if="col.sortable" class="inline-block mt-1 ml-1">
            <svg class="fill-current h-4 w-4" :class="activeSortObj.field === col.name ? 'text-blue-500': 'text-gray-900 opacity-25'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path v-if="activeSortObj.field === col.name && activeSortObj.dir === 'desc'" d="M6 3l-6 8h4v10h4v-10h4l-6-8zm16 14h-8v-2h8v2zm2 2h-10v2h10v-2zm-4-8h-6v2h6v-2zm-2-4h-4v2h4v-2zm-2-4h-2v2h2v-2z"/>
              <path v-else d="M6 21l6-8h-4v-10h-4v10h-4l6 8zm16-4h-8v-2h8v2zm2 2h-10v2h10v-2zm-4-8h-6v2h6v-2zm-2-4h-4v2h4v-2zm-2-4h-2v2h2v-2z"/>
            </svg>
          </div>
        </div>
      </div>
      <div v-if="isBusy">
        <div class="flex w-full py-3 px-5">
          <div class="flex items-center text-center w-full">
            <font-awesome-icon icon="spinner" size="2x" spin class="mr-3" />
            Ładuję...
          </div>
        </div>
      </div>
      <template v-else>
        <div v-for="employee in employees" :key="employee.id" class="flex flex-wrap w-full py-3 px-5 border-b border-gray-400 last:border-b-0">
          <div class="flex" :class="columns[0].classes">
            <font-awesome-icon v-if="Number(employee.disabled) === 1" icon="user-tie" class="mt-1 mr-1 text-gray-300 cursor-pointer" title="wyłączony" />
            <font-awesome-icon v-else icon="user-tie" class="mt-1 mr-1 text-green-300 cursor-pointer" title="aktywny" />
            <router-link :to="{ name: 'employee', params: { id: employee.id }, query: $route.query }" class="text-lg font-semibold text-gray-600 hover:no-underline hover:text-gray-700">{{ employee.name }}</router-link>
          </div>
          <div class="flex" :class="columns[1].classes">
            <a :href="`mailto:${employee.person.email}`">{{ employee.person.email }}</a>
          </div>
          <div class="flex" :class="columns[2].classes">
            <a :href="`tel:${employee.person.phone}`">{{ employee.person.phone | phoneNo }}</a>
          </div>
          <div class="flex flex-col" :class="columns[3].classes">
            <p v-for="(role, index) in employee.roles" :key="index">{{ role }}</p>
          </div>
          <div class="flex text-right" :class="columns[4].classes">
            <span>{{ employee.id }}</span>
          </div>
        </div>
      </template>
    </div>
    <EmployeeAdd
      v-if="showEmployeeAdd"
      @close="showEmployeeAdd = false"
      @changed="getEmployees"
    ></EmployeeAdd>
    <router-view />
  </div>
</template>

<script>
import EmployeeService from '../services/EmployeeService';
import Pagination from '../components/Pagination.vue';
import EmployeeAdd from '../components/EmployeeAdd.vue';
import { errorHandler } from '../mixins/errorHandler';

export default {
  name: 'Employees',
  components: {
    Pagination,
    EmployeeAdd,
  },
  mixins: [errorHandler],
  data() {
    return {
      query: {},
      queryDefaults: {
        page: 1,
        perPage: 20,
        order: 'name,asc',
      },
      isBusy: true,
      employees: [],
      search: '',
      status: null,
      role: null,
      searchFields: [
        { value: 'or_name', label: 'Nazwisko', active: false },
        { value: 'or_email', label: 'Email', active: false },
        { value: 'or_phone', label: 'Telefon', active: false },
      ],
      pagData: {
        links: {},
        meta: {},
        perPage: 10,
      },
      sortSelected: '',
      sortOptions: [
        { value: 'name,asc', text: 'nazwisko a-z' },
        { value: 'name,desc', text: 'nazwisko z-a' },
        { value: 'email,asc', text: 'email a-z' },
        { value: 'email,desc', text: 'email z-a' },
        { value: 'id,asc', text: 'id rosnąco' },
        { value: 'id,desc', text: 'id malejąco' },
      ],
      columns: [
        { name: 'name', label: 'Nazwisko', sortable: true, href: 'id', classes: 'w-full sm:w-1/2 lg:w-1/4' },
        { name: 'email', label: 'email', sortable: true, href: 'email', classes: 'w-full sm:w-1/2 lg:w-1/4' },
        { name: 'phone', label: 'telefon', sortable: false, href: 'phone', classes: 'w-full sm:w-1/2 lg:w-1/4 xl:w-1/6' },
        { name: 'roles', label: 'rola(e)', sortable: false, href: '', classes: 'w-full sm:w-1/2 lg:w-1/4' },
        { name: 'id', label: 'id', sortable: true, href: '', classes: 'hidden xl:block xl:w-1/12' },
      ],
      employeeStatusOptions: [
        { text: 'Status (wszystkie)', value: null },
        { text: 'Aktywny', value: 0 },
        { text: 'Wyłączony', value: 1 },
      ],
      showEmployeeAdd: false,
    };
  },
  computed: {
    employeeRoles() {
      return this.$store.getters['roles/employeeRoles'];
    },
    searchAll() {
      return this.activeSearchFields.length === 0 || this.activeSearchFields.length === this.searchFields.length;
    },
    activeSortObj() {
      const sort = this.$route.query.order !== undefined ? this.$route.query.order.split(',') : this.queryDefaults.order.split(',');
      return {
        field: sort[0],
        dir: sort[1],
      };
    },
    activeSearchFields() {
      return this.searchFields.filter(obj => obj.active).map(field => field.value);
    },
    listNeedsUpdate() {
      return this.$store.state.updates.employeeList;
    },
  },
  mounted() {
    this.setQueryFromUri();
    if (this.employeeRoles.length === 0) {
      this.$store.dispatch('roles/getEmployeeRoles');
    }
  },
  methods: {
    setQueryFromUri() {
      let changed = 0;
      if (Number.isNaN(Number.parseInt(this.$route.query.page, 10))) {
        this.query.page = 1;
        changed += 1;
      } else {
        this.query.page = Number.parseInt(this.$route.query.page, 10);
      }
      if (Number.isNaN(Number.parseInt(this.$route.query.perpage, 10))) {
        this.query.perpage = this.pagData.perPage;
        changed += 1;
      } else {
        this.query.perpage = Number.parseInt(this.$route.query.perpage, 10);
      }

      if (this.$route.query.order === undefined) {
        this.query.order = 'name,asc';
        changed += 1;
      } else {
        this.query.order = this.$route.query.order;
      }
      this.sortSelected = this.query.order;

      if (this.$route.query.search !== undefined && this.$route.query.search !== '') {
        this.query.search = this.$route.query.search;
        this.search = decodeURI(this.$route.query.search);
        this.searchFields.forEach(searchField => {
          if (this.$route.query[searchField.value] !== undefined) {
            this.query[searchField.value] = encodeURI(this.$route.query[searchField.value]);
            // eslint-disable-next-line no-param-reassign
            searchField.active = true;
            // this.activeSearchFields.push(searchField.value);
          }
        });
      }

      if (this.$route.query.disabled !== undefined) {
        if (this.$route.query.disabled === '0' || this.$route.query.disabled === '1') {
          this.query.disabled = this.$route.query.disabled;
          this.status = this.$route.query.disabled === '0' ? '0' : '1';
        }
      }

      if (this.$route.query.role !== undefined) {
        this.query.role = this.$route.query.role;
        this.role = this.$route.query.role;
      }

      if (changed > 0) {
        const { name, params } = this.$route;
        this.$router.replace({ name, params, query: this.query });
      } else {
        this.getEmployees();
      }
    },
    getEmployees() {
      this.isBusy = true;
      const query = {};
      Object.keys(this.$route.query).forEach(key => { query[key] = decodeURI(this.$route.query[key]); });
      EmployeeService.getEmployees(query)
        .then(response => {
          this.isBusy = false;
          this.pagData.links = response.data.links;
          this.pagData.meta = response.data.meta;
          this.pagData.perPage = Number.parseInt(response.data.meta.per_page, 10);
          this.employees = response.data.data.result;
          this.$store.dispatch('updates/employeeListUpdated');
        })
        .catch(error => {
          this.isBusy = false;
          this.resolveError(error); // method from mixin
        });
    },
    linkGen(pageNum) {
      const query = {};
      Object.assign(query, this.$route.query);
      query.page = pageNum;
      return {
        name: 'employees',
        query,
      };
    },
    selectSort() {
      const routeName = this.$route.name;
      Object.assign(this.query, this.$route.query);
      if (this.query.order === this.sortSelected) {
        return false;
      }
      this.query.order = this.sortSelected;
      this.$router.push({ name: routeName, query: this.query });
      return true;
    },
    perPageChanged() {
      const routeName = this.$route.name;
      Object.assign(this.query, this.$route.query);
      this.query.perpage = this.pagData.perPage;
      this.query.page = 1;
      this.$router.push({ name: routeName, query: this.query });
    },
    resetFilters() {
      this.search = '';
      this.filterGo();
    },
    setSearchAll() {
      // this.activeSearchFields = [];
      // eslint-disable-next-line no-param-reassign
      this.searchFields.forEach(obj => { obj.active = false; });
    },
    statusChange() {
      Object.assign(this.query, this.$route.query);
      this.query.page = 1;
      delete this.query.disabled;
      if (this.status === 0) {
        this.query.disabled = 0;
      } else if (this.status === 1) {
        this.query.disabled = 1;
      }
      this.$router.push({ name: 'employees', query: this.query });
    },
    roleChange() {
      Object.assign(this.query, this.$route.query);
      this.query.page = 1;
      delete this.query.role;
      if (this.role) {
        this.query.role = this.role;
      }
      this.$router.push({ name: 'employees', query: this.query });
    },
    filterGo() {
      Object.assign(this.query, this.$route.query);
      delete this.query.search;
      this.searchFields.forEach(obj => {
        delete this.query[obj.value];
      });
      this.query.page = 1;
      if (this.search !== '') {
        this.query.search = encodeURI(this.search);
        this.activeSearchFields.forEach(field => {
          this.query[field] = 1;
        });
      }
      this.$router.push({ name: 'employees', query: this.query });
    },
  },
  watch: {
    $route(to, from) {
      if (JSON.stringify(to.query) !== JSON.stringify(from.query)) {
        this.getEmployees();
      }
    },
    listNeedsUpdate(newValue) {
      if (newValue) {
        this.getEmployees();
      }
    },
    sortSelected: 'selectSort',
    'pagData.perPage': 'perPageChanged',
  },
};
</script>

<style scoped>
  .list-group {
    @apply mt-2;
    @apply border;
    @apply rounded-sm;
    @apply border-gray-400;
  }
</style>
