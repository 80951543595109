<template>
  <div class="relative" ref="wrapper">
    <input
      type="text"
      class="input" :class="inputClass"
      :value="value"
      @input="updateValue($event.target.value)"
      @keydown.enter = "enter(-1)"
      @keydown.down = "down"
      @keydown.up = "up"
      @keydown.esc = "esc"
      @keydown.tab = "open = false"
      :disabled="disabledInput"
    >
    <div
      v-show="openSuggestion"
      class="absolute top-0 mt-8 left-0 w-11/12 bg-gray-100 border shadow-lg z-10"
    >
      <p class="text-xs text-red-500" v-if="tooManySugg">Zbyt wiele dopasowań - wpisz więcej znaków aby zawęzić wyszukiwanie</p>
      <div v-if="! tooManySugg">
        <a href=""
          class="block px-3 text-sm"
          v-for="(suggestion, index) in suggestions"
          :key="index"
          :class="{'bg-blue-400 text-white': isActive(index), 'text-gray-600': !isActive(index)}"
          @click.prevent="enter(index)"
        >
          {{ suggestion.autocompl_value !== undefined ? suggestion.autocompl_value : suggestion.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import debounce from 'lodash/debounce';
import { errorHandler } from '../mixins/errorHandler';

export default {
  name: 'Autocomplete',
  mixins: [errorHandler],
  props: {
    value: {
      type: String,
      // required: true
    },
    resource: {
      type: String,
      required: true,
    },
    inputClass: {
      type: String,
      default: '',
    },
    disabledInput: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    document.addEventListener('click', this.documentClick);
  },
  destroyed() {
    document.removeEventListener('click', this.documentClick);
  },
  data() {
    return {
      open: false,
      current: 0,
      suggestions: [],
    };
  },
  computed: {
    tooManySugg() {
      return this.suggestions.length > 49;
    },
    openSuggestion() {
      return this.value !== '' && this.suggestions.length !== 0 && this.open === true;
    },
  },
  methods: {
    updateValue(value) {
      if (this.open === false && value.length > 2) {
        this.suggestions = [];
        this.open = true;
        // this.$emit('selected', {});
        this.current = 0;
      }
      if (value.length > 2) {
        this.getSuggestions(value);
      }
      this.$emit('input', value);
      this.suggestions = [];
    },
    // eslint-disable-next-line prefer-arrow-callback
    getSuggestions: debounce(function (value) {
      axios.get(`${process.env.VUE_APP_API_URL}/${this.resource}?search=${value}`)
        .then(response => {
          this.suggestions = response.data.data;
        })
        .catch(error => this.resolveError(error));
    }, 400),
    enter(pos) {
      let valueIndex = pos;
      if (pos === -1) {
        valueIndex = this.current;
      }
      const inputValue = this.suggestions[valueIndex].name;
      this.$emit('input', inputValue);
      this.$emit('selected', this.suggestions[valueIndex]);
      this.open = false;
    },
    up() {
      if (this.current > 0) {
        this.current -= 1;
      }
    },
    down() {
      if (this.current < this.suggestions.length - 1) {
        this.current += 1;
      }
    },
    esc() {
      this.open = false;
    },
    isActive(index) {
      return index === this.current;
    },
    documentClick(e) {
      const { wrapper } = this.$refs;
      const { target } = e;
      if (!wrapper.contains(target)) {
        this.open = false;
      }
    },
  },
};
</script>
